<template>

	<div>

		<!-- Filters -->
		<questions-list-filters
			:options="options"
			:question-types-filter.sync="questionTypesFilter"
        />
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto">
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar pregunta ..."
								trim
							/>
							<b-button
                                variant="primary"
                                class="ml-auto"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								:to="{ name: 'questions-create'}"
                            >
								<span class="text-nowrap">Agregar Pregunta</span>
							</b-button>
						</div>
					</b-col>

				</b-row>

			</div>

			<b-table
				ref="refQuestionListTable"
				class="position-relative table-questions"
				:style="totalQuestions === 1 ? 'height: 200px' : 'height: inherit'"
				:items="fetchQuestions"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>

				<template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

				<template #head(answers_count)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(code)="data">
					<b-link
						:to="{ name: 'questions-edit', params: { id: data.item.id } }"
						class="font-weight-bold d-block text-nowrap"
					>
						{{ data.item.code }}
					</b-link>
				</template>

				<template #cell(question)="data">
					<span class="d-block text-nowrap">
						{{ data.item.question.length > 90 ? `${data.item.question.substring(0, 90)}...` : data.item.question }}
					</span>
                </template>

				<template #cell(question_type_name)="data">
					<span class="d-block text-nowrap">
						<b-badge :variant="data.item.question_type_color">
							{{ data.item.question_type_name }}
						</b-badge>
					</span>
                </template>

				<template #cell(answers_count)="data">
                    <span class="d-block text-nowrap">
						{{ data.item.answers_count }}
					</span>
				</template>

				<template #cell(createdAt)="data">
                    <span class="d-block text-nowrap">
						{{ moment(data.item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
					</span>
				</template>
				
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item :to="{ name: 'questions-edit', params: { id: data.item.id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteQuestion(data.item.id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalQuestions"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>
		
	</div>

</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
	import QuestionsListFilters from './QuestionsListFilters.vue';
	import { useToast } from 'vue-toastification/composition';
	import questionStoreModule from '../questionStoreModule';
	import useQuestionsList from './useQuestionsList';
	import VueSweetalert2 from 'vue-sweetalert2';
	import Ripple from 'vue-ripple-directive';
	import vSelect from 'vue-select';
	import store from '@/store';
	import moment from 'moment';
	import axios from '@axios';
	import Vue from 'vue';    
	import 'animate.css';
	
	Vue.use(VueSweetalert2);

	export default {
		components: {
			QuestionsListFilters,
			vSelect
		},
		directives: {
            Ripple
        },
		data() {
            return {
                moment
            }
        },
		setup() {

			// USE TOAST
			const toast = useToast();

			// REFS
			const options = ref({});

			const QUESTION_APP_STORE_MODULE_NAME = 'app-question';

			// REGISTER MODULE
			if (!store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.registerModule(QUESTION_APP_STORE_MODULE_NAME, questionStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUESTION_APP_STORE_MODULE_NAME);
			});

			// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
                getSelectFilters();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/selects/question-types');
                options.value = data;
            };
		
			const deleteQuestion = (id) => {
		
				Vue.swal({
					title: '¿Está seguro(a) de eliminar la pregunta?',
					text: '¡Si no lo está, puede cancelar la acción!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, eliminar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) 
					{
						store.dispatch('app-question/deleteQuestion', { id })
							.then( response => {

								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

							})
							.catch( () => {

								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al eliminar la pregunta',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});

							});
					}

				});

			};
			
			const {
				fetchQuestions,
				tableColumns,
				perPage,
				currentPage,
				totalQuestions,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refQuestionListTable,
				refetchData,

				// EXTRA FILTERS
                questionTypesFilter
			} = useQuestionsList();
			
			return {
				fetchQuestions,
				tableColumns,
				perPage,
				currentPage,
				totalQuestions,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refQuestionListTable,
				refetchData,

				// REFS
				options,

				// EXTRA FILTERS
                questionTypesFilter,
				
				// ACTIONS
				deleteQuestion
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
        align-items: center;
    }

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>